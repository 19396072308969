<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile mb-4">
          <div class="column">
            <p class="subtitle has-text-primary is-capitalized">
              {{ label }}
            </p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="$modal.close">Close</a>
          </div>
        </div>
        <div v-html="info" class="content" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DataTableInfo',
  computed: {
    ...mapGetters({ data: 'modal/config' }),
    label() {
      return this.data.label
    },
    info() {
      return this.data.info
    }
  }
}
</script>
